#masterplan {
  margin-top: 40px;
  background-color: rgb(94, 185, 245);
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  #title {
    color: #ffffff;
    padding-top: 60px;
    margin-bottom: 20px;
    text-align: center;
  }
  #masterplan-button {
    border-radius: 999px;
  }
  #visualizadorMasterplan {
    width: 100%;
    height: 550px;
  }
}
