#proyecto {
  padding-top: 180px;
  margin-bottom: 70px;
  #title {
    margin-bottom: 30px;
    text-align: center;
    color: rgb(94, 185, 245);
  }

  #imagenProyecto {
    aspect-ratio: 1 / 1;
    width: 38%;
    border-radius: 999px;
    margin-right: 5%;
  }
  #videoButton {
    margin-top: 20px;
  }
}

@media (max-width: 1399px) {
  #proyecto {
    padding-top: 100px;
  }
}

@media (max-width: 769px) {
  #proyecto {
    padding-top: 60px;
    #contentContainer {
      flex-direction: column;
    }
    #imagenProyecto {
      aspect-ratio: 1 / 1;
      width: 80%;
      border-radius: 999px;
      margin-right: 5%;
    }
  }
}
