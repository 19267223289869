#sector {
  margin-top: 60px;
  margin-bottom: 60px;
  #title {
    text-align: center;
    padding-bottom: 30px;
    color: rgb(94, 185, 245);
  }
  #subtitle {
    text-align: center;
    color: rgb(94, 185, 245);
    margin-bottom: -12px;
  }
  .carousel-item {
    max-height: 500px;
  }
  .carousel-text {
    text-shadow: 1px 1px 4px #202020;
  }
  .carousel-list {
    list-style: none;
  }
}

@media (max-width: 1199px) {
  #sector {
    .carousel-item,
    .carousel-image {
      height: 350px;
    }
  }
}

@media (max-width: 455px) {
  #sector {
    .carousel-item,
    .carousel-image {
      height: 450px;
    }
  }
}
