#footer {
  background-color: rgb(94, 185, 245);
  z-index: 3;
  #footer-content {
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
  .footer-item {
    color: #ffffff;
  }
}
