#navbar {
  font-family: "Public Sans", sans-serif;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  #logo {
    width: 190px;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .linksContainer {
    width: 100%;
    // display: flex;
    // justify-content: space-around;
    text-align: right;
  }
  .nav-link {
    color: #060606;
    font-size: 24px;
    margin-right: 10px;
    &:hover {
      color: #c9c8c8;
    }
  }
  .navbar-nav .show > .nav-link,
  .navbar-nav .nav-link.active {
    color: rgb(94, 185, 245);
  }
}

.navbar {
  background-color: #ffffff;
}

@media (max-width: 1200px) {
  #navbar {
    .nav-link {
      font-size: 20px;
    }
  }
}
