#ventajas {
  margin-top: 20px;
  padding-top: 40px;
  width: 100%;
  top: 0;
  z-index: 9;
  #ventajas-content-container {
    z-index: 3;
  }
  #title {
    margin-bottom: 20px;
  }
  #contentContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    gap: 50px;
  }
  #caracteristicasContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 300px;
    width: 48%;
    margin: 0;
  }
  .caracteristicaContainer {
    display: flex;
  }
  .caracteristicaIcon {
    color: rgb(51, 164, 240);
    margin-right: 10px;
  }
  #listIcon {
    width: 30px;
  }
  #imagen {
    width: 100%;
    height: 300px;
    overflow: hidden;
  }
}
#ubicacion {
  margin-bottom: 50px;
  #mapContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    #mapImage {
      width: 100%;
      min-width: 350px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 992px) {
  #ventajas {
    #contentContainer {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    #caracteristicasContainer,
    #mapContainer {
      width: 100%;
    }
  }
}
