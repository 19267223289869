#contacto {
  background-color: #e6e6e6;
  border-radius: 20px;
  width: 100%;
  #title {
    font-size: 42px;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 0;
  }
  #description {
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }
  #contentContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  #formContainer {
    width: 100%;
    min-width: 300px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #spinner {
    width: 70px;
    height: 70px;
  }
  #form {
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
  #buttonContainer {
    text-align: center;
  }
  #formButton {
    width: 200px;
  }
}

@media (max-width: 994px) {
  #contacto {
    margin-top: -100px;
  }
}
