#home {
  padding-top: 20px;
  padding-bottom: 20px;
  .home-content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .home-content-container-side {
    max-width: 48%;
  }
  #background-color {
    width: 100vw;
    height: 1000px;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 1),
      rgba(1, 0, 0, 0)
    );
    opacity: 0.9;
  }
  #background-image {
    width: 100vw;
    height: 1000px;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -2;
    background-image: url("../../assets//img/video-poster-img.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  #preTitle {
    font-size: 100px;
  }
  #title {
    margin-top: 220px;
    font-size: 75px;
    color: #ffffff;
    text-shadow: 2px 2px 5px #202020;
    line-height: 65px;
    margin-bottom: 40px;
  }
  #subtitle {
    font-size: 36px;
    color: #ffffff;
    text-shadow: 2px 2px 5px #202020;
  }
  .home-text {
    font-size: 20px;
    color: #ffffff;
    text-shadow: 2px 2px 5px #202020;
  }
  .homeButton {
    width: 200px;
    margin-top: 40px;
    margin-right: 20px;
    border-radius: 100px;
  }
}

@media (max-width: 994px) {
  #home {
    padding-top: 0px;
    #background-color {
      height: 1200px;
    }
    #background-image {
      height: 1200px;
    }
    #preTitle {
      font-size: 70px;
    }
    #title {
      font-size: 60px;
      line-height: 55px;
    }
    #homeCardsContainer {
      display: none;
    }
    #homeGeneralCard {
      display: block;
    }
    .home-content-container {
      justify-content: center;
    }
    .home-content-container-side {
      max-width: 100%;
    }
  }
}

@media (max-width: 500px) {
  #home {
    .home-text {
      font-size: 16px;
    }
  }
}

@media (max-width: 450px) {
  #home {
    #preTitle {
      font-size: 60px;
    }
    #title {
      font-size: 40px;
      margin-bottom: 0px;
      line-height: 40px;
    }
    #subtitle {
      font-size: 25px;
    }
    .homeButton {
      width: 185px;
      margin-top: 20px;
      margin-right: 10px;
    }
  }
}
